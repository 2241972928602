
.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin: 20px;
}

.gridItem {
  position: relative;
  overflow: hidden;
}

.grey {
  color: rgb(70, 66, 66);
}

.hrLine {
  width: 11%;
  margin-left: 0;
  margin-bottom: 25px;
  border: 1.5px solid red;
  border-radius: 4px;
  margin-top: -5px;
}

.gridItem img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  opacity: 0.8;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.gridItem img:hover {
  opacity: 1;
  animation: zoomIn 0.5s forwards; 
}

.gridItem img:not(:hover) {
  animation: zoomOut 0.5s forwards; 
} 

.gridItem h3 {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 5px 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.gridItem .abbtn {
  position: absolute;
  bottom: 20px;
  left: 30px;
  margin: 0;
  padding: 7px 14px;
  color: black;
  background-color: white;
  border-radius: 25px;
}

.gridItem .pacbtn {
  position: absolute;
  bottom: 20px;
  right: 30px;
  padding: 7px 14px;
  margin: 0;
  color: black;
  background-color: white;
  border-radius: 25px;
}

.abbtn:hover, .pacbtn:hover {
  cursor: pointer;
  animation: zoomIn 0.5s forwards;
}

@media (max-width: 767px) {
  .hrLine {
    width: 30%;
  }
}
