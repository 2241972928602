
.carousel {
  width: 100vw;
  height: 86vh;
  overflow: hidden;
}

.carousel .slick-slider {
  height: 100%;
}

.carousel .slick-list {
  height: 100%;
}

.carousel .slick-track {
  height: 100%;
}

.carousel .slick-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.carousel-image:hover {
  animation: zoomIn 0.5s forwards;
}

.carousel-image:not(:hover) {
  animation: zoomOut 0.5s forwards;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background: rgba(2, 2, 2, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.carousel-content h2 {
  font-size: 3vw;
  margin-bottom: 10px;
}

.carousel-content p {
  font-size: 1.5vw;
  margin-bottom: 10px;
}

.btnover {
  padding: 10px 20px;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btnover:hover {
  box-shadow: 8px 8px 8px rgb(19, 20, 19);
}

@media (max-width: 768px) {
  .carousel-content {
    padding: 10px;
  }
  .carousel-content h2 {
    font-size: 6vw;
  }
  .carousel-content p {
    font-size: 4vw;
  }
  .btnover {
    padding: 5px 10px;
    font-size: 4vw;
  }
}

@media (max-width: 480px) {
  .carousel {
    height: 60vh;
  }
  .carousel-content h2 {
    font-size: 8vw;
  }
  .carousel-content p {
    font-size: 5vw;
  }
  .btnover {
    padding: 5px 8px;
    font-size: 5vw;
  }
}