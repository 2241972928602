
.cubc {
    background-image: url("../Images/handshakeimg.jpg");
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    text-align: center;
    padding: 20px;
  }
  
  .cucont {
    color: white;
    font-size: 2rem;
  }
  
  @media (min-width: 768px) {
    .cubc {
      background-size: 100vw 100vh;
    }
  
    .cucont {
      font-size: 3rem;
    }
  }
  
