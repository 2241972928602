/* src/navBar.css */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  border-radius: 50%;
}

.menu-toggle {
  display: none;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-right: 20px;
}

button {
  background-color: rgb(213, 49, 49);
  text-decoration: none;
  border: transparent;
  border-radius: 6px;
  font-size: small;
  padding: 6px 18px;
}

.btnclr {
  color: white;
  text-decoration: none;
}

.navNames {
  color: white;
  text-decoration: none;
  font-size: small;
}

.navNames:hover {
  background-image: linear-gradient(to top right, rgba(11, 96, 11, 0.911), lightgreen);
  border: transparent;
  color: white;
  box-shadow: 4px 8px 15px rgb(63, 59, 59);
  border-radius: 6px;
}

button:hover {
  box-shadow: 4px 8px 15px rgb(12, 11, 11);
}

.nav-links .navNames {
  display: block;
  padding: 6px 18px;
  margin-right: 30px;
}

.nav-links a.active {
  background-image: linear-gradient(to top right, rgba(11, 96, 11, 0.911), lightgreen);
  border: transparent;
  color: white;
  border-radius: 6px;
}
.nav-links li button{
  margin-right: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .menu-toggle {
    display: block; /* Show only on mobile screens */
    position: absolute;
    top: 13px;
    right: 26px;
    
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .nav-links li button {
    margin: 10px 0;
  }

  .nav-links .navNames {
    margin: 10px 0;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }

  .logo {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .nav-links .navNames {
    font-size: small;
  }

  button {
    font-size: small;
  }

  .logo {
    height: 35px;
  }
}
