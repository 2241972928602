/* internationalTours.css */
.card {
    position: relative;
    width: 100%;
    height: 80px;
    padding-top: 100%; /* Aspect ratio 1:1 */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }

  .card-content {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%; 
    padding: 20px; 
    color: rgb(15, 15, 15);
    /* text-align: center; */
    background: rgba(235, 232, 232, 0.902);
    width: 100%;
    border-radius: 8px;
  }
  .best-seller {
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: #ff0000;
    color: #fff;
    padding: 1px 7px 4px 14px; 
    /* border-radius: 5px;  */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 14px;
  }
  h2, p {
    margin: 0;
  }
