
 .flexContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 25px;
    background-color: rgb(177, 177, 172);
    justify-content: space-around;
}

.box1, .box2, .box3 {
    width: 100%;
    max-width: 420px;
    height: auto;
    margin: 0 10px;
}

.flex1 {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 8px;
}

.flexbox1 {
    width: 100%;
    max-width: 200px;
}

.flexbox1 p {
    width: 100%;
}

.flexbox1 p:hover {
    cursor: pointer;
}

.box3 p:hover {
    cursor: pointer;
}

.redLine {
    width: 9%;
    margin-left: 0;
    border: 1.5px solid red;
    margin-top: -12px;
    border-radius: 4px;
}

.dottedLine {
    border: 1px dotted grey;
}

.icon {
    color: red;
}

.contact {
    color: red;
}

.footer {
    background-color: grey;
    padding: 15px 25px;
    text-align: center;
}

@media (min-width: 768px) {
    .flexContainer {
        flex-wrap: nowrap;
    }

    .box1, .box2, .box3 {
        width: 30%;
    }
}

@media (max-width: 767px) {
    .box1, .box2, .box3 {
        width: 100%;
    }
    .flex1{
        display: flex;
        flex-wrap: wrap;
    }
}
