.tourBc{
    background-image: url("../Images/bridge.jpg");
    background-size:100vw 100vh;
    background-position: center;
    height: 70vh;
    display: flex;
    justify-content: center;
}
.tourContent h1{
    color: white;
    font-size: 40px;
    position: relative;
    top: 45vh;
}
.tourContent p{
    color: white;
    font-size: large;
    position: relative;
    top: 43vh;

}
.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}
  