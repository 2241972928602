.formStyle{
    background-color: rgb(152, 185, 211);
    padding: 40px;
    width: 600px;
    border-radius: 10px;
    backdrop-filter: blur(degree-of-blur);
    box-shadow: 2px 4px 15px grey;
}
/* Adjustments for tablet and mobile screens */
@media (max-width: 768px) {
    .formStyle {
      width: 80%; /* Reduce width for smaller screens */
      padding: 20px;
    }
  
    .formStyle .form-label,
    .formStyle .form-control,
    .formStyle .form-text {
      font-size: 1rem; /* Adjust font sizes for readability */
    }
  }
  
  @media (max-width: 480px) {
    .formStyle {
      width: 90%; /* Further reduce width for very small screens */
      padding: 15px;
    }
  
    .formStyle .form-label,
    .formStyle .form-control,
    .formStyle .form-text {
      font-size: 0.9rem; /* Further adjust font sizes */
    }
  
    .formStyle button {
      width: 100%; /* Make the button full width */
      padding: 10px;
      font-size: 1rem; /* Adjust font size of the button */
    }
  }