
.contact-form-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .FormFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: 2px 4px 10px grey;
    width: 90%;
    max-width: 800px;
    background-color: white;
  }
  
  .firstBox, .secondBox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .firstBox {
    background-image: linear-gradient(to bottom right, skyblue, rgb(227, 227, 216));
    border-radius: 6px;
    height: 100%;
  }
  
  .firstBox h2 {
    font-size: 35px;
  }
  
  .firstBox p {
    margin-bottom: 15px;
  }
  
  .contact-info {
    color: red;
  }
  
  input, .form-control, .form-select {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .formSubmit {
    display: block;
    width: 100%;
    color: white;
    background-color: #007bff;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .formSubmit:hover {
    background-color: #0056b3;
    cursor: pointer;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .icons>* {
    margin: 0 10px;
    border: 1px solid rgb(56, 54, 48);
    border-radius: 20px;
    padding: 6px;
  }
  
  .icons>*:hover {
    cursor: pointer;
  }
  
  @media (min-width: 768px) {
    .FormFlex {
      flex-direction: row;
    }
  
    .firstBox, .secondBox {
      width: 50%;
    }
  
    .formSubmit {
      width: auto;
      position: relative;
      left: auto;
    }
  }
  