.aboutbc{
    background-image: url("../Images/aboutusimg.jpg");
    background-size:100vw 100vh;
    background-position: center;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.abcont{
    color: white;
    font-size: xx-large;
}